var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    attrs: {
      "title": "Badanie lekarskie"
    }
  }, [_vm.employeeId || _vm.employeeId === 0 ? _c('examination-brief', {
    attrs: {
      "targetId": _vm.employeeId,
      "items": _vm.medicalExams
    }
  }) : _c('content-placeholders', {
    staticClass: "mt-2",
    attrs: {
      "rounded": ""
    }
  }, [_c('content-placeholders-text', {
    attrs: {
      "lines": 2
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }