import i18n from '@/libs/i18n';
import store from '@/store';
import ApiClient from '@/services/api';
import { ref, computed } from 'vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import showToast from '@/libs/toasts';
import {
  AddExaminationSuccess,
  AddExaminationError,
  FetchExaminationHistoryError,
  DeleteExaminationSuccess,
  DeleteExaminationError,
} from '@feedback/module/cadre';
import { deleteModal } from '@/libs/modals';

/**
 * Medical Examinations Hook
 *
 * @param {Array} item Most recent examination
 * @param {Number} employeeId Target of exam
 * @returns {Object} hookObject Public variables and methods
 */
export const useMedicalExaminationBrief = (employeeId = null, item = {}) => {
  const vModel = ref({});
  const exam = computed(() => item.value ?? {});
  const targetId = employeeId;

  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);

  const history = ref([]);

  // ready to send object with neccessary
  // properties injected
  const examinationData = computed(() => ({
    ...vModel.value,
    employee_id: targetId,
  }));

  const examsUpToDate = computed(() => exam.value.up_to_date);
  const examsAboutToExpire = computed(() => exam.value.about_to_expire);
  const daysTillExpiration = computed(() => daysFromToday(exam.value.expiration_date));

  const briefMessage = computed(() => {
    if (examsAboutToExpire.value && daysTillExpiration.value && daysTillExpiration.value <= 0) return i18n.tc('cadre.medicalExams.field.expiringIn', Math.abs(daysTillExpiration.value));

    if (examsUpToDate.value) return i18n.t('cadre.medicalExams.field.upToDate');
    return i18n.t('cadre.medicalExams.field.outdated');
  });

  const expirationDate = computed(() => formatDate.medicalExpiration(exam.value.expiration_date));

  const addExamination = () => {
    const formData = new FormData();

    Object.keys(examinationData?.value).map((e) => {
      const name = examinationData?.value?.[e];
      formData.append(e, name);
      return true;
    });

    const request = ApiClient.post('api/cadre/medical-exam', formData);

    request
      .then(() => {
        vModel.value = {};
        store.dispatch('cadre/employees/fetchOne', { employeeId, force: true });
        showToast(AddExaminationSuccess);
      })
      .catch(() => {
        showToast(AddExaminationError);
      });
  };

  const fetchExaminationHistory = () => {
    const request = ApiClient.get(`api/cadre/medical-exam/all-employee/${employeeId}`);

    request
      .then(({ data }) => {
        history.value = data.data;
      })
      .catch(() => {
        showToast(FetchExaminationHistoryError);
      });

    return request;
  };

  const deleteExamination = async (examination) => {
    const confirmation = await deleteModal({
      title: i18n.t('cadre.medicalExams.modal.delete.title'),
      text: i18n.t('cadre.medicalExams.modal.delete.text', {
        id: examination.id,
      }),
    });

    if (!confirmation) return;

    const request = ApiClient.delete(`api/cadre/medical-exam/${examination.id}`);

    request
      .then(() => {
        history.value = history.value.filter((entry) => entry.id !== examination.id);
        store.dispatch('cadre/employees/fetchOne', { employeeId, force: true });
        showToast(DeleteExaminationSuccess);
      })
      .catch(() => {
        showToast(DeleteExaminationError);
      });
  };

  const updateExamination = () => {
    const formData = new FormData();

    // TODO: move to a helper
    Object.keys(examinationData?.value).map((e) => {
      const name = examinationData?.value?.[e];
      if (name !== null) formData.append(e, name);
      return true;
    });

    const request = ApiClient.post(`api/cadre/medical-exam/${vModel.value.id}`, formData);

    request
      .then(({ data }) => {
        history.value = history.value.map((entry) => {
          if (entry.id !== data?.data?.id) return entry;
          return data?.data;
        });
        vModel.value = {};
        store.dispatch('cadre/employees/fetchOne', { employeeId, force: true });
        showToast(AddExaminationSuccess);
      })
      .catch(() => {
        showToast(AddExaminationError);
      });
  };

  const openEditModal = (editedExam) => {
    vModel.value = {
      ...editedExam,
      _filename: editedExam?.file?.original_name,
      file: null,
    };
    modalEditVisible.value = true;
  };

  return {
    exam,
    history,
    vModel,
    examsUpToDate,
    examsAboutToExpire,
    briefMessage,
    expirationDate,
    modalAddVisible,
    modalEditVisible,

    /* methods */
    openEditModal,
    addExamination,
    updateExamination,
    deleteExamination,
    fetchExaminationHistory,
  };
};

export const useMedicalExaminations = (employeeId, item = {}) => ({
  ...useMedicalExaminationBrief(employeeId, item),
});

export default useMedicalExaminations;
