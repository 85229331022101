var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_vm.employee.notes ? _c('notes-component', {
    attrs: {
      "items": _vm.employee.notes
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }