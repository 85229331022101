import ApiClient from '@/services/api';
import { ref } from 'vue';
import showToast from '@/libs/toasts';
import {
  EditEmployeeSkillsSuccess,
  EditEmployeeSkillsError,
} from '@feedback/module/cadre';

const useEmployeeSkills = (employeeId, employeeSkills = []) => {
  const skills = ref([...employeeSkills]);

  const editorVisible = ref(false);
  const editorSkills = ref([]);

  const toggleEditor = (show = null) => {
    editorSkills.value = skills.value.map((skill) => skill.id);
    editorVisible.value = show ?? !editorVisible.value;
  };

  const editorSaveSkills = () => {
    const request = ApiClient.patch('/api/cadre/skill/assign', {
      skill_ids: editorSkills.value,
      employee_id: employeeId,
    });

    request
      .then(({ data }) => {
        showToast(EditEmployeeSkillsSuccess);
        skills.value = data.data;
        toggleEditor(false);
      })
      .catch(() => {
        showToast(EditEmployeeSkillsError);
      });

    return request;
  };

  return {
    skills,
    editorVisible,
    editorSkills,

    editorSaveSkills,
    toggleEditor,
  };
};

export default useEmployeeSkills;
