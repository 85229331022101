import showToast from '@/libs/toasts';
import { FetchPickerEmployeesError } from '@/libs/toasts/feedback/module/cadre';
import { ref } from 'vue';
import useCadreApi from '../useCadreApi';

// prevent requesting same data over and over
// TODO: part of a major picker refactors
const fetchAttempted = ref(false);
const employees = ref([]);
const shouldUpdateEmployees = ref(true);

export default function useCadreEmployee() {
  const fetchEmployees = () => {
    if (!shouldUpdateEmployees.value) return employees;
    fetchAttempted.value = true;

    const req = useCadreApi()
      .fetchPickerEmployees()
      .then(({ data }) => {
        employees.value = data.data;
        shouldUpdateEmployees.value = false;
      })
      .catch(() => {
        fetchAttempted.value = false;
        showToast(FetchPickerEmployeesError);
      });

    return req;
  };

  return { employees, fetchEmployees, shouldUpdateEmployees };
}
