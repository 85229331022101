var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre-details",
    class: {
      visible: _vm.detailsVisible()
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "12",
      "md": "8",
      "xl": "5"
    }
  }, [_c('worker-card')], 1), _vm.shouldDisplayExaminations ? _c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "2"
    }
  }, [_c('examinations-card')], 1) : _vm._e(), _c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "12",
      "xl": _vm.shouldDisplayExaminations ? '5' : '7'
    }
  }, [_c('bhp-card')], 1), _c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "12"
    }
  }, [_c('permissions-card')], 1), _c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "8"
    }
  }, [_vm.isCurrentRight && _vm.files ? _c('attachments-card', {
    attrs: {
      "files": _vm.files
    }
  }) : _vm._e()], 1), _c('b-col', {
    staticClass: "card-column",
    attrs: {
      "cols": "4"
    }
  }, [_c('notes-card')], 1)], 1)], 1), false ? _c('b-card-group', {
    attrs: {
      "columns": ""
    }
  }, [_c('worker-card'), _c('examinations-card'), _c('bhp-card'), _c('permissions-card'), _c('attachments-card'), _c('notes-card')], 1) : _vm._e(), _c('tasks-create-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }