<template>
  <widget-card>
    <skills-list
      v-if="employeeId || employeeId === 0"
    />

    <content-placeholders rounded class="mt-2" v-else>
      <content-placeholders-text :lines="1"/>
    </content-placeholders>
  </widget-card>
</template>

<script>
import { inject, computed } from 'vue';
import SkillsList from '@/components/views/cadre/ui/SkillsList.vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';

export default {
  setup() {
    const employee = inject('employee');
    const employeeId = computed(() => employee.value?.id);

    return { employeeId };
  },
  components: {
    WidgetCard,
    SkillsList,
  },
};
</script>
