<template>
  <widget-card>
    <template #header>

      <b-alert
        variant="danger"
        class="w-100 employee-archived-alert"
        :show="!!employee.deleted_at"
      >
        <div class="employee-archived-info">
          <div class="text">
            <feather-icon icon="InfoIcon" />
            Pracownik zarchiwizowany
          </div>

          <vs-button
            vs-variant="link"
            @click="() => restoreEmployee(employee)"
          >
            Przywróć
          </vs-button>

        </div>
      </b-alert>

      <div class="spacer" v-if="!!employee.deleted_at" />

      <div class="profile">

        <avatar
          :deleted="!!employee.deleted_at"
          lg
          :user="employee"
          v-if="employeeFetched"
          :displayUserIcon="!!employee.user_core_id"
        />

        <div class="details">
          <content-placeholders v-if="!employeeFetched" rounded>
            <content-placeholders-heading :img="true"/>
          </content-placeholders>

          <div class="name" v-if="employeeFetched">
            <user-label
              :withAvatar="false"
              :data="{ ...employee, id: employee.user_core_id }"
            />
          </div>

          <a
            class="email"
            :href="`mailto:${employee.email}`"
            v-if="employeeFetched"
          >
            {{ employee.email }}
          </a>
        </div>

        <widget-card-controls
          edit-permission="cadre.employee.update"
          delete-permission="cadre.employee.destroy"
          @edit="() => $router.push({ name: 'cadre.employee.edit' })"
          @delete="() => deleteOrArchiveEmployee(employee)"
          :hideEdit="!!employee.deleted_at"
        />
      </div>
    </template>

    <div
      class="action-buttons"
      v-if="employee.user_core_id"
    >
      <b-button-group>
        <vs-button
          @click="openTaskModal"
          vs-variant="primary"
          v-can:store="'tasks.task'"
          >
          + Dodaj zadanie
        </vs-button>
      </b-button-group>
    </div>

    <div>
      <content-placeholders class="mt-2" v-if="!employeeFetched" rounded>
        <content-placeholders-text :lines="7"/>
      </content-placeholders>

      <table v-else>
        <tbody>
          <tr>
            <td>status</td>
            <td>
              <status-badge :status_id="employee.status_id" />
            </td>
          </tr>
          <tr>
            <td>region</td>
            <td><branch-label :id="employee.company_branch_id"/></td>
          </tr>
          <tr>
            <td>grupa</td>
            <td><group-label :id="employee.group ? employee.group.id : null" /></td>
          </tr>
          <tr>
            <td>stanowisko</td>
            <td><position-label :id="employee.position ? employee.position.id : null" /></td>
          </tr>
          <tr>
            <td>stawka dzienna</td>
            <td>
              <template v-if="!employee.position">-</template>
              <template v-else>
                {{ (employee.position.actual_daily_cost || 0).toLocaleString() }}
                PLN
              </template>
            </td>
          </tr>
          <tr>
            <td>przełożony</td>
            <td>
              <cadre-employee-label
                with-avatar
                name-only
                :id="employee.supervisor_id"
                v-if="employee.supervisor_id"
              />
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td>e-mail</td>
            <td>
              <a
                class="email"
                :href="`mailto:${employee.email}`"
              >
                {{ employee.email }}
              </a>
            </td>
          </tr>
          <tr>
            <td>nr telefonu</td>
            <td>{{ employee.phone_number }}</td>
          </tr>
          <tr>
            <td>forma zatrudnienia</td>
            <td>{{ employmentForms || '-' }}</td>
          </tr>
          <tr>
            <td>początek umowy</td>
            <td>{{ formatDate.employeeContract(employee.contract_start) }}</td>
          </tr>
          <tr>
            <td>koniec umowy</td>
            <td>
              <examination-badge
                v-if="employee.contract_end"
                :expired="daysFromToday(employee.contract_end) > 0"
                :about-to-expire="daysFromToday(employee.contract_end) >= -30"
                :date="employee.contract_end"
              />
              <span style="opacity: 0.6" v-else>
                {{ $t('cadre.list.field.indefinite') }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </widget-card>
</template>

<script>
import store from '@/store';
import { computed } from 'vue';
import { mapGetters } from 'vuex';
import { BButtonGroup, BAlert } from 'bootstrap-vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import Avatar from '@/components/ui/Avatar.vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import StatusBadge from '@/components/ui/status-badge/Cadre.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import BranchLabel from '@/components/ui/BranchLabel.vue';

import GroupLabel from '@/components/views/cadre/ui/GroupLabel.vue';
import PositionLabel from '@/components/views/cadre/ui/PositionLabel.vue';

import useEmployee from '@/hooks/cadre/useEmployee';
import WidgetCardControls from '@/components/ui/WidgetCardControls.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useTasksModals from '@/hooks/tasks/useTasksModals';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';
import CadreEmployeeLabel from '../ui/CadreEmployeeLabel.vue';

export default {
  components: {
    BButtonGroup,

    WidgetCard,
    StatusBadge,
    Avatar,
    UserLabel,
    BranchLabel,
    GroupLabel,
    PositionLabel,
    WidgetCardControls,
    VsButton,
    BAlert,
    CadreEmployeeLabel,
    ExaminationBadge,
  },
  setup() {
    const { deleteEmployee, archiveEmployee, restoreEmployee } = useEmployee();
    const { modalCreateTask } = useTasksModals();

    const employee = computed(() => store.getters['cadre/employees/current']);

    const openTaskModal = () => {
      modalCreateTask.value.open({
        assigned_id: employee.value?.user_core_id,
      });
    };

    const deleteOrArchiveEmployee = (args) => (employee.value.deleted_at
      ? deleteEmployee(args)
      : archiveEmployee(args));

    return {
      openTaskModal,
      formatDate,
      deleteOrArchiveEmployee,
      restoreEmployee,
      daysFromToday,
    };
  },
  computed: {
    ...mapGetters({
      employee: 'cadre/employees/current',
    }),
    employmentForms() {
      return this.employee?.form_of_employment?.map((form) => form.name).join(', ');
    },
    employeeFetched() {
      return this.employee && !!Object.keys(this.employee).length;
    },
  },
};
</script>

<style lang="sass" scoped>
.employee-archived-alert
  position: absolute
  top: 0
  left: 0
  border-radius: 7px 7px 0 0
  margin-bottom: 20px
  border: 1px solid rgba(red, 0.2)
  border-bottom: none
  padding-bottom: 50px
  padding-top: 5px
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%)
  -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%)

  .employee-archived-info
    display: flex
    align-items: center
    gap: 5px
    padding: 0px 15px
    border-radius: 5px
    width: 100%

    .text
      display: flex
      gap: 5px
      align-items: center

    .vs-button
      margin-left: auto

.spacer
  display: block
  width: 100%
  height: 40px

.profile
  display: flex
  align-items: center
  width: 100%

  .details
    margin-left: 20px
    width: 100%

    .name
      font-size: 14pt
      font-weight: 600

table
  margin-top: 20px
  line-height: 1.7

  td:nth-child(1)
    font-weight: 300
  td:nth-child(2)
    padding-left: 20px
</style>
