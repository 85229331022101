<template>
  <div class="cadre-employee-safety-trainings">
    <div
      v-can:index="'cadre.safety_training'"
    >
      <table v-if="trainings.length > 0">
        <thead>
          <th>Nazwa</th>
          <th>Data szkolenia</th>
          <th>Data ważności</th>
        </thead>
        <tbody>
          <tr v-for="training in trainings" :key="training.id">
            <td>{{ training.name }}</td>
            <td>{{ formatDate.training(training.training_date) }}</td>
            <td class="expiry-date">
              <examination-badge
                :expired="!training && daysFromToday(training.expiration_date) < 0"
                :about-to-expire="(training && training.about_to_expire)
                  && training.expiration_date"
                :date="training.expiration_date"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="no-trainings" v-else>
        {{ $t('cadre.safetyTrainings.title.emptyHistory', { name: employee.full_name }) }}
      </div>
    </div>

    <div class="control-buttons">
      <vs-button
        vs-variant="secondary"
        @click="openAddTrainingModal"
        v-can:store="'cadre.safety_training'"
        v-if="!employee.deleted_at"
        >
        Dodaj szkolenie
      </vs-button>
      <vs-button
        vs-variant="light"
        @click="$router.push({ name: 'cadre.safety-trainings', params: {
          employeeId: employee.id
        } })"
        v-can:index="'cadre.safety_training'"
        >
        Historia
      </vs-button>
    </div>

    <cadre-training-add
      @success="addTrainingSuccess"
      :editedEmployee="employee"
    />
  </div>
</template>

<script>
import { inject, provide } from 'vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useSafetyTrainings from '@/hooks/cadre/useSafetyTrainings';
import CadreTrainingAdd from '@/components/views/cadre/ui/safety-trainings/modals/CadreTrainingAdd.vue';
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import store from '@/store';

export default {
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    targetId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    if (!props.targetId && props.targetId !== 0) return null;
    const { modalAddTraining } = useCadreModals();

    const {
      trainings,
      vModel,
      modalAddVisible,
      isLoading,
    } = useSafetyTrainings(props.items, props.targetId);

    const updateModel = (model) => {
      vModel.value = model;
    };

    const employee = inject('employee');

    provide('v-model', vModel);
    provide('updateModel', updateModel);

    const openAddTrainingModal = () => {
      modalAddTraining.value.open({ employee_id: employee.value.id });
    };

    const addTrainingSuccess = () => {
      store.dispatch('cadre/employees/fetchOne', { employeeId: employee.value.id, force: true })
        .then(({ data }) => {
          trainings.value = data.data.safety_training;
        });
    };

    return {
      trainings,
      vModel,
      modalAddVisible,
      employee,
      isLoading,

      formatDate,
      daysFromToday,
      openAddTrainingModal,
      addTrainingSuccess,
    };
  },
  components: {
    CadreTrainingAdd,
    ExaminationBadge,
    VsButton,
  },
};
</script>

<style lang="sass" scoped>
.control-buttons
  padding: 20px 0
  position: absolute
  bottom: 0

  .btn
    display: block
    margin: 5px 0
    margin-top: 10px

table
  width: 100%

  td
    padding: 10px 0
</style>
