<template>
  <widget-card title="Badanie lekarskie">
    <examination-brief
      v-if="employeeId || employeeId === 0"
      :targetId="employeeId"
      :items="medicalExams"
    />
    <content-placeholders rounded class="mt-2" v-else>
      <content-placeholders-text :lines="2"/>
    </content-placeholders>

  </widget-card>
</template>

<script>
import { inject } from 'vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import useModal from '@/hooks/useModal';
import ExaminationBrief from '@/components/views/cadre/ui/examinations/ExaminationBrief.vue';
import useEmployee from '@/hooks/cadre/useEmployee';

export default {
  setup(_props, context) {
    const { openModal } = useModal();

    const employee = inject('employee');
    const employeeId = useEmployee().currentId;
    const medicalExams = useEmployee().medical_exam;

    const openModale = () => {
      openModal('modal-task-add', context, {
        title: 'Wykonaj badanie lekarskie',
        priority_id: 3,
        assigned_id: employee.value?.id,
      });
    };

    return { openModale, employeeId, medicalExams };
  },
  components: {
    WidgetCard,
    ExaminationBrief,
  },
};
</script>

<style lang="sass" scoped>
.action-button
  margin: 10px 0
</style>
