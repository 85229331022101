import store from '@/store';
import router from '@/router';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import { computed } from 'vue';

import showToast from '@/libs/toasts';
import {
  DeleteError,
  DeleteSuccess,
  ArchiveError,
  ArchiveSuccess,
  RestoreError,
  RestoreSuccess,
} from '@feedback/module/cadre';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import useCadreEmployee from './assignables/useCadreEmployee';

const { shouldUpdateEmployees } = useCadreEmployee();

const deleteEmployee = async (employee) => {
  const { forename, surname } = employee;

  const confirmation = await deleteModal({
    title: i18n.t('cadre.employee.modal.delete.title'),
    text: i18n.t('cadre.employee.modal.delete.text', {
      name: `${forename || ''} ${surname || ''}`,
    }),
  });

  if (!confirmation) return false;

  const response = store.dispatch('cadre/employees/delete', { employee });

  response.then(() => {
    showToast(DeleteSuccess, { name: employee.full_name });
    router.push({ name: 'cadre' });

    RemoteResourceManager.getInstance()
      .notify('cadre.employee');

    if (employee.user_core_id) {
      RemoteResourceManager.getInstance()
        .notify('core.user');
    }
  });

  response.catch(() => {
    showToast(DeleteError, {});
  });

  return response;
};

const archiveEmployee = async (employee) => {
  const { forename, surname } = employee;

  const confirmation = await deleteModal({
    title: i18n.t('cadre.employee.modal.archive.title'),
    text: i18n.t('cadre.employee.modal.archive.text', {
      name: `${forename || ''} ${surname || ''}`,
    }),
    confirmButtonText: 'Archiwizuj',
  });

  if (!confirmation) return false;

  const response = store.dispatch('cadre/employees/delete', { employee, archive: true });

  response.then(() => {
    showToast(ArchiveSuccess, { name: employee.full_name });
    shouldUpdateEmployees.value = true;
    store.commit('users/SET_PROMISE', null);

    RemoteResourceManager.getInstance()
      .notify('cadre.employee');

    if (employee.user_core_id) {
      RemoteResourceManager.getInstance()
        .notify('core.user');
    }
  });

  response.catch(() => {
    showToast(ArchiveError, {});
  });

  return response;
};

const restoreEmployee = async (employee) => {
  const { forename, surname } = employee;

  const confirmation = await deleteModal({
    title: i18n.t('cadre.employee.modal.restore.title'),
    text: i18n.t('cadre.employee.modal.restore.text', {
      name: `${forename || ''} ${surname || ''}`,
    }),
    confirmButtonText: 'Przywróć',
  });

  if (!confirmation) return false;

  const response = store.dispatch('cadre/employees/restore', employee);

  response.then(() => {
    showToast(RestoreSuccess, { name: employee.full_name });
    shouldUpdateEmployees.value = true;
    store.commit('users/SET_PROMISE', null);

    RemoteResourceManager.getInstance()
      .notify('cadre.employee');

    if (employee.user_core_id) {
      RemoteResourceManager.getInstance()
        .notify('core.user');
    }
  });

  response.catch(() => {
    showToast(RestoreError, {});
  });

  return response;
};

const useEmployee = () => ({
  current: computed(() => store.getters['cadre/employees/current']),
  currentId: computed(() => store.getters['cadre/employees/current']?.id),
  medical_exam: computed(() => store.getters['cadre/employees/current']?.medical_exam),

  fetch: (id) => store.dispatch('cadre/employees/fetchOne', { employeeId: id }),
  deleteEmployee: (employee) => deleteEmployee(employee),
  archiveEmployee: (employee) => archiveEmployee(employee),
  restoreEmployee: (employee) => restoreEmployee(employee),
});

export default useEmployee;
