<template>
  <div class="skills-list">
    <header-section
      :title="$t('cadre.skills.title')"
      :action="$t('cadre.skills.button.add')"
      @actionClick="toggleEditor"
      :canAction="$store.getters['auth/userHasPermission']('assign', 'cadre.skill')
        && !employee.deleted_at"
    >
      <ul v-if="!editorVisible">
        <li
          v-for="skill in skills"
          :key="skill.id"
          v-can:index="'cadre.skill'"
        >
          {{ skill.name }}
        </li>
      </ul>

      <div class="skills-editor" v-else>
        <cadre-skill-picker
          multiple
          v-model="editorSkills"
          :closeOnSelect="false"
        />

        <b-button
          variant="primary"
          @click="editorSaveSkills"
          >
          Zapisz
        </b-button>
      </div>
    </header-section>
  </div>
</template>

<script>
import { inject } from 'vue';
import { BButton } from 'bootstrap-vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import useEmployeeSkills from '@/hooks/cadre/useEmployeeSkills';
import CadreSkillPicker from '@/components/module/cadre/CadreSkillPicker.vue';

export default {
  setup() {
    const employee = inject('employee');

    const {
      skills,
      editorSkills,
      editorVisible,

      editorSaveSkills,
      toggleEditor,
    } = useEmployeeSkills(employee.value?.id, employee.value?.skills);

    return {
      editorVisible,
      editorSkills,
      skills,
      employee,

      editorSaveSkills,
      toggleEditor,
    };
  },
  components: {
    BButton,
    HeaderSection,
    CadreSkillPicker,
  },
};
</script>

<style lang="sass" scoped>
ul
  margin: 0
  padding: 0
  list-style-type: none

  li
    margin: 0
    margin-right: 10px
    display: inline-block
    position: relative

    &:not(:first-child)
      padding-left: 15px

      &:before
        width: 5px
        height: 5px
        aspect-ratio: 1
        top: calc(50% - 2px)
        border-radius: 50%
        opacity: 0.3
        left: 0
        position: absolute
        background: black
        content: ''
</style>
