var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    attrs: {
      "title": "Szkolenia BHP"
    }
  }, [_vm.employeeId ? _c('safety-trainings', {
    attrs: {
      "targetId": _vm.employeeId,
      "items": _vm.employee.safety_training
    }
  }) : _c('content-placeholders', {
    staticClass: "mt-2",
    attrs: {
      "rounded": ""
    }
  }, [_c('content-placeholders-text', {
    attrs: {
      "lines": 3
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }