var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('b-alert', {
          staticClass: "w-100 employee-archived-alert",
          attrs: {
            "variant": "danger",
            "show": !!_vm.employee.deleted_at
          }
        }, [_c('div', {
          staticClass: "employee-archived-info"
        }, [_c('div', {
          staticClass: "text"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Pracownik zarchiwizowany ")], 1), _c('vs-button', {
          attrs: {
            "vs-variant": "link"
          },
          on: {
            "click": function () {
              return _vm.restoreEmployee(_vm.employee);
            }
          }
        }, [_vm._v(" Przywróć ")])], 1)]), !!_vm.employee.deleted_at ? _c('div', {
          staticClass: "spacer"
        }) : _vm._e(), _c('div', {
          staticClass: "profile"
        }, [_vm.employeeFetched ? _c('avatar', {
          attrs: {
            "deleted": !!_vm.employee.deleted_at,
            "lg": "",
            "user": _vm.employee,
            "displayUserIcon": !!_vm.employee.user_core_id
          }
        }) : _vm._e(), _c('div', {
          staticClass: "details"
        }, [!_vm.employeeFetched ? _c('content-placeholders', {
          attrs: {
            "rounded": ""
          }
        }, [_c('content-placeholders-heading', {
          attrs: {
            "img": true
          }
        })], 1) : _vm._e(), _vm.employeeFetched ? _c('div', {
          staticClass: "name"
        }, [_c('user-label', {
          attrs: {
            "withAvatar": false,
            "data": Object.assign({}, _vm.employee, {
              id: _vm.employee.user_core_id
            })
          }
        })], 1) : _vm._e(), _vm.employeeFetched ? _c('a', {
          staticClass: "email",
          attrs: {
            "href": `mailto:${_vm.employee.email}`
          }
        }, [_vm._v(" " + _vm._s(_vm.employee.email) + " ")]) : _vm._e()], 1), _c('widget-card-controls', {
          attrs: {
            "edit-permission": "cadre.employee.update",
            "delete-permission": "cadre.employee.destroy",
            "hideEdit": !!_vm.employee.deleted_at
          },
          on: {
            "edit": function () {
              return _vm.$router.push({
                name: 'cadre.employee.edit'
              });
            },
            "delete": function () {
              return _vm.deleteOrArchiveEmployee(_vm.employee);
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm.employee.user_core_id ? _c('div', {
    staticClass: "action-buttons"
  }, [_c('b-button-group', [_c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'tasks.task',
      expression: "'tasks.task'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openTaskModal
    }
  }, [_vm._v(" + Dodaj zadanie ")])], 1)], 1) : _vm._e(), _c('div', [!_vm.employeeFetched ? _c('content-placeholders', {
    staticClass: "mt-2",
    attrs: {
      "rounded": ""
    }
  }, [_c('content-placeholders-text', {
    attrs: {
      "lines": 7
    }
  })], 1) : _c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("status")]), _c('td', [_c('status-badge', {
    attrs: {
      "status_id": _vm.employee.status_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("region")]), _c('td', [_c('branch-label', {
    attrs: {
      "id": _vm.employee.company_branch_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("grupa")]), _c('td', [_c('group-label', {
    attrs: {
      "id": _vm.employee.group ? _vm.employee.group.id : null
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("stanowisko")]), _c('td', [_c('position-label', {
    attrs: {
      "id": _vm.employee.position ? _vm.employee.position.id : null
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("stawka dzienna")]), _c('td', [!_vm.employee.position ? [_vm._v("-")] : [_vm._v(" " + _vm._s((_vm.employee.position.actual_daily_cost || 0).toLocaleString()) + " PLN ")]], 2)]), _c('tr', [_c('td', [_vm._v("przełożony")]), _c('td', [_vm.employee.supervisor_id ? _c('cadre-employee-label', {
    attrs: {
      "with-avatar": "",
      "name-only": "",
      "id": _vm.employee.supervisor_id
    }
  }) : _c('span', [_vm._v("-")])], 1)]), _c('tr', [_c('td', [_vm._v("e-mail")]), _c('td', [_c('a', {
    staticClass: "email",
    attrs: {
      "href": `mailto:${_vm.employee.email}`
    }
  }, [_vm._v(" " + _vm._s(_vm.employee.email) + " ")])])]), _c('tr', [_c('td', [_vm._v("nr telefonu")]), _c('td', [_vm._v(_vm._s(_vm.employee.phone_number))])]), _c('tr', [_c('td', [_vm._v("forma zatrudnienia")]), _c('td', [_vm._v(_vm._s(_vm.employmentForms || '-'))])]), _c('tr', [_c('td', [_vm._v("początek umowy")]), _c('td', [_vm._v(_vm._s(_vm.formatDate.employeeContract(_vm.employee.contract_start)))])]), _c('tr', [_c('td', [_vm._v("koniec umowy")]), _c('td', [_vm.employee.contract_end ? _c('examination-badge', {
    attrs: {
      "expired": _vm.daysFromToday(_vm.employee.contract_end) > 0,
      "about-to-expire": _vm.daysFromToday(_vm.employee.contract_end) >= -30,
      "date": _vm.employee.contract_end
    }
  }) : _c('span', {
    staticStyle: {
      "opacity": "0.6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cadre.list.field.indefinite')) + " ")])], 1)])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }