<template>
  <widget-card>
    <notes-component v-if="employee.notes" :items="employee.notes"/>
  </widget-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { NotesComponent } from '@/hooks/components/useNotesComponent';
import WidgetCard from '@/components/ui/WidgetCard.vue';

export default {
  computed: {
    ...mapGetters({
      employee: 'cadre/employees/current',
    }),
  },
  components: {
    WidgetCard,
    NotesComponent,
  },
};
</script>

<style lang="sass" scoped>
</style>
