<template>
  <widget-card title="Szkolenia BHP">

    <safety-trainings
      v-if="employeeId"
      :targetId="employeeId"
      :items="employee.safety_training"
    />

    <content-placeholders rounded class="mt-2" v-else>
      <content-placeholders-text :lines="3"/>
    </content-placeholders>

  </widget-card>
</template>

<script>
import { computed } from 'vue';
import store from '@/store';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import SafetyTrainings from '@/components/views/cadre/ui/safety-trainings/SafetyTrainings.vue';

export default {
  setup() {
    const employee = computed(() => store.getters['cadre/employees/current']);
    const employeeId = computed(() => employee.value?.id);

    return {
      employee,
      employeeId,
    };
  },
  components: {
    WidgetCard,
    SafetyTrainings,
  },
  created() {
    const { id } = this.$route.params;
    this.$store.dispatch('cadre/employees/safety/fetch', id);
  },
};
</script>

<style lang="sass" scoped>
.action-button
  margin: 10px 0
</style>
