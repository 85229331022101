<template>
  <div class="cadre-employee-examination-brief">

    <div
      v-can:show="'cadre.medical_exam'"
    >
      <examination-badge hide-text
        class="mb-1"
        :expired="!examsUpToDate"
        :about-to-expire="examsAboutToExpire"
        :date="exam.expiration_date"
      />

      <h3>{{ briefMessage }}</h3>
      <p v-if="examsUpToDate && expirationDate">do {{ expirationDate }} r.</p>
      <p v-else-if="examsUpToDate">bezterminowe</p>
      <p v-else-if="expirationDate">od {{ expirationDate }} r.</p>
      <p v-if="exam.medical_exam_type">
        Badanie {{ $t(`cadre.medicalExams.type.${exam.medical_exam_type}`).toLowerCase() }}
      </p>
    </div>

    <div class="control-buttons">

      <vs-button
        v-if="isModuleEnabled('Tasks') && employee.user_core_id && !employee.deleted_at"
        vs-variant="secondary"
        @click="openExaminationTaskModal"
        v-can:store="'tasks.task'"
        >
        Zleć badanie
      </vs-button>

      <vs-button
        vs-variant="light"
        @click="openExaminationModal"
        v-can:store="'cadre.medical_exam'"
        v-if="!employee.deleted_at"
        >
        Dodaj badanie
      </vs-button>

      <vs-button
        vs-variant="light"
        @click="$router.push({ name: 'cadre.examinations', params: { employeeId: employee.id } })"
        v-can:index="'cadre.medical_exam'"
        >
        Historia
      </vs-button>

    </div>

    <cadre-examination-add
      @success="examinationAddSuccess"
      :editedEmployee="employee"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { computed, provide, inject } from 'vue';
import CadreExaminationAdd from '@/components/views/cadre/ui/examinations/modals/CadreExaminationAdd.vue';
import { useMedicalExaminations } from '@/hooks/cadre/useMedicalExaminations';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import store from '@/store';
import useTasksModals from '@/hooks/tasks/useTasksModals';

export default {
  props: {
    items: {
      type: Object,
      default: () => ({}),
    },
    targetId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const employee = inject('employee');

    const items = computed(() => employee.value.medical_exam);

    const instanceMedicalExaminations = useMedicalExaminations(props.targetId, items);
    provide('instanceMedicalExaminations', instanceMedicalExaminations);

    const { modalAddExamination } = useCadreModals();
    const openExaminationModal = () => modalAddExamination.value
      .open({ employee_id: employee.value.id });

    const {
      exam,
      addExamination,
      examsUpToDate,
      examsAboutToExpire,
      briefMessage,
      expirationDate,
      modalAddVisible,
      vModel,
    } = instanceMedicalExaminations;

    const updateModel = (model) => {
      vModel.value = model;
    };

    provide('v-model', vModel);
    provide('updateModel', updateModel);

    const { modalCreateTask } = useTasksModals();
    const openExaminationTaskModal = () => {
      modalCreateTask.value.open({
        title: 'Wykonaj badanie lekarskie',
        priority_id: 3,
        assigned_id: employee.value?.user_core_id,
      });
    };

    const examinationAddSuccess = () => {
      store.dispatch('cadre/employees/fetchOne', {
        employeeId: employee.value.id,
        force: true,
      });
    };

    return {
      exam,
      addExamination,
      examsUpToDate,
      examsAboutToExpire,
      briefMessage,
      expirationDate,
      modalAddVisible,
      vModel,
      employee,

      openExaminationModal,
      openExaminationTaskModal,
      examinationAddSuccess,
    };
  },
  computed: {
    ...mapGetters({
      isModuleEnabled: 'app/isModuleEnabled',
    }),
  },
  components: {
    CadreExaminationAdd,
    ExaminationBadge,
    VsButton,
  },
};
</script>

<style lang="sass" scoped>
.cadre-employee-examination-brief

  .control-buttons
    padding: 20px 0
    position: absolute
    bottom: 0

    .btn
      margin: 5px 0
</style>
