var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "skills-list"
  }, [_c('header-section', {
    attrs: {
      "title": _vm.$t('cadre.skills.title'),
      "action": _vm.$t('cadre.skills.button.add'),
      "canAction": _vm.$store.getters['auth/userHasPermission']('assign', 'cadre.skill') && !_vm.employee.deleted_at
    },
    on: {
      "actionClick": _vm.toggleEditor
    }
  }, [!_vm.editorVisible ? _c('ul', _vm._l(_vm.skills, function (skill) {
    return _c('li', {
      directives: [{
        name: "can",
        rawName: "v-can:index",
        value: 'cadre.skill',
        expression: "'cadre.skill'",
        arg: "index"
      }],
      key: skill.id
    }, [_vm._v(" " + _vm._s(skill.name) + " ")]);
  }), 0) : _c('div', {
    staticClass: "skills-editor"
  }, [_c('cadre-skill-picker', {
    attrs: {
      "multiple": "",
      "closeOnSelect": false
    },
    model: {
      value: _vm.editorSkills,
      callback: function ($$v) {
        _vm.editorSkills = $$v;
      },
      expression: "editorSkills"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.editorSaveSkills
    }
  }, [_vm._v(" Zapisz ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }