import ApiClient from '@/services/api';
import { ref, computed } from 'vue';
import showToast from '@/libs/toasts';
import {
  AddTrainingSuccess,
  AddTrainingError,
  DeleteTrainingError,
  DeleteTrainingSuccess,
  FetchTrainingHistoryError,
} from '@feedback/module/cadre';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import store from '@/store';

/**
 * Safety Trainings Hook
 *
 * @param {Array} items Trainings list
 * @param {Number} employeeId Target of trainings
 * @returns {Object} hookObject Public variables and methods
 */
const useSafetyTrainings = (items, employeeId) => {
  const vModel = ref({});
  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);
  const targetId = employeeId;
  const trainings = ref([...items]);
  const isLoading = ref(false);

  const trainingData = computed(() => ({
    ...vModel.value,
    employee_id: targetId,
  }));

  const addTraining = (e) => {
    isLoading.value = true;
    e.preventDefault();

    const formData = new FormData();

    Object.keys(trainingData?.value).map((x) => {
      const name = trainingData?.value?.[x];
      formData.append(x, name);
      return true;
    });

    const request = ApiClient.post('api/cadre/safety-training', formData);

    request
      .then(() => {
        vModel.value = {};
        showToast(AddTrainingSuccess);
        store.dispatch('cadre/employees/fetchOne', { employeeId, force: true })
          .then(({ data }) => {
            trainings.value = data.data.safety_training;
          });
        modalAddVisible.value = false;
      })
      .catch(() => {
        showToast(AddTrainingError);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const history = ref([]);

  const fetchTrainingHistory = () => {
    const request = ApiClient.get(`api/cadre/safety-training/history/${employeeId}`);

    request
      .then(({ data }) => {
        history.value = data.data;
      })
      .catch(() => {
        showToast(FetchTrainingHistoryError);
      });

    return request;
  };

  const deleteTraining = async (training) => {
    const confirmation = await deleteModal({
      title: i18n.t('cadre.safetyTrainings.modal.delete.title'),
      text: i18n.t('cadre.safetyTrainings.modal.delete.text', {
        name: training.name,
      }),
    });

    if (!confirmation) return;

    const request = ApiClient.delete(`api/cadre/safety-training/${training.id}`);

    request
      .then(() => {
        fetchTrainingHistory();

        store.commit('cadre/employees/DELETE_TRAINING', training);
        showToast(DeleteTrainingSuccess);
      })
      .catch(() => {
        showToast(DeleteTrainingError);
      });
  };

  const updateTraining = () => {
    isLoading.value = true;
    const formData = new FormData();

    // TODO: move to a helper
    Object.keys(trainingData?.value).map((e) => {
      const name = trainingData?.value?.[e];
      if (name !== null) formData.append(e, name);
      return true;
    });

    const request = ApiClient.post(`api/cadre/safety-training/${vModel.value.id}`, formData);

    request
      .then(({ data }) => {
        vModel.value = {};
        fetchTrainingHistory();

        store.dispatch('cadre/employees/fetchOne', { employeeId, force: true })
          .then((res) => {
            trainings.value = res.data.data.safety_training;
          });

        history.value = history.value.map((entry) => {
          if (entry.id !== data?.data?.id) return entry;
          return data?.data;
        });

        store.commit('cadre/employees/UPDATE_TRAINING', data?.data);
        showToast(DeleteTrainingSuccess);
        // showToast(AddExaminationSuccess);
      })
      .catch(() => {
        // showToast(AddExaminationError);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const openEditModal = (editedTraining) => {
    vModel.value = {
      ...editedTraining,
      _filename: editedTraining?.file?.original_name,
      file: null,
      delete_file: false,
    };
    modalEditVisible.value = true;
  };

  return {
    trainings,
    history,
    vModel,
    modalAddVisible,
    modalEditVisible,
    isLoading,

    /* methods */
    addTraining,
    updateTraining,
    deleteTraining,
    fetchTrainingHistory,
    openEditModal,
  };
};

export default useSafetyTrainings;
