<template>
  <div class="cadre-details" :class="{ visible: detailsVisible() }">
    <b-container fluid>

      <b-row>

        <b-col class="card-column" cols="12" md="8" xl="5">
          <worker-card />
        </b-col>

        <b-col class="card-column" cols="12" md="4" xl="2"
          v-if="shouldDisplayExaminations"
          >
          <examinations-card />
        </b-col>

        <b-col class="card-column" cols="12" :xl="shouldDisplayExaminations ? '5' : '7'">
          <bhp-card />
        </b-col>

        <b-col class="card-column" cols="12">
          <permissions-card />
        </b-col>

        <b-col class="card-column" cols="8">
          <attachments-card
            v-if="isCurrentRight && files"
            :files="files"
          />
        </b-col>

        <b-col class="card-column" cols="4">
          <notes-card />
        </b-col>
      </b-row>

    </b-container>

    <b-card-group columns v-if="false">
      <worker-card />
      <examinations-card />
      <bhp-card />
      <permissions-card />
      <attachments-card />
      <notes-card />
    </b-card-group>

    <tasks-create-modal />
  </div>
</template>

<script>
import { computed, provide, watch } from 'vue';
import {
  BContainer,
  BRow,
  BCol,
  BCardGroup,
} from 'bootstrap-vue';
import WorkerCard from '@/components/views/cadre/widgets/WorkerCard.vue';
import PermissionsCard from '@/components/views/cadre/widgets/PermissionsCard.vue';
import ExaminationsCard from '@/components/views/cadre/widgets/ExaminationsCard.vue';
import BhpCard from '@/components/views/cadre/widgets/BhpCard.vue';
import AttachmentsCard from '@/components/views/cadre/widgets/AttachmentsCard.vue';
import NotesCard from '@/components/views/cadre/widgets/NotesCard.vue';
import TasksCreateModal from '@/components/views/tasks/modals/TasksCreateModal.vue';

import { FetchEmployeeError } from '@feedback/module/cadre';
import store from '@/store';
import { useRouter } from '@/@core/utils/utils';

export default {
  setup() {
    const current = computed(() => store.getters['cadre/employees/current']);
    const { route } = useRouter();

    // FIXME change this variable name
    const isCurrentRight = computed(() => {
      const { id } = route.value.params;
      return current.value.id === Number(id);
    });

    const shouldDisplayExaminations = computed(() => !isCurrentRight.value
      || current.value.medical_exam_required);

    provide('employee', current);

    const files = computed(() => current.value.files);
    watch(() => isCurrentRight.value, (isRight) => {
      if (isRight) return;
      const { id } = route.value.params;
      store.dispatch('cadre/employees/fetchOne', { employeeId: id, force: true });
    });

    watch(() => isCurrentRight.value, (isRight) => {
      if (isRight) return;
      const { id } = route.value.params;
      store.dispatch('cadre/employees/fetchOne', { employeeId: id, force: true });
    });

    return {
      files, current, isCurrentRight, shouldDisplayExaminations,
    };
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BCardGroup,

    WorkerCard,
    PermissionsCard,
    ExaminationsCard,
    BhpCard,
    AttachmentsCard,
    NotesCard,

    TasksCreateModal,
  },
  methods: {
    detailsVisible() {
      return this.$route.name === 'cadre-details';
    },
  },
  created() {
    this.$store
      .dispatch('cadre/employees/fetchOne', { employeeId: this.$route.params.id })
      .catch(() => {
        this.$showFeedback(FetchEmployeeError, {});
        this.$router.push({ name: 'cadre' });
      });
  },
};
</script>

<style lang="sass" scoped>
.card-column
  align-items: stretch
</style>
